.services-image-icon {
  width: 30px !important;
  height: 30px !important;
  fill: #005000 !important;
  margin-right: 7px !important;
  padding-top: -7px !important;
}

.services-image-icon-sm {
  width: 15px !important;
  height: 15px !important;
  fill: #005000 !important;
}

#profile-link {
  margin-left: 950px;
  border: 1px dotted #005000 !important;
}


