.carousel-item {
  max-height: 100vh;
}

.logoImage {
  animation-name: zoom;
  animation-duration: 10s; /* Adjust the duration for a slower animation */
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1); /* Initial scale */
  }
  50% {
    transform: scale(1.03); /* Zoom in */
  }
  100% {
    transform: scale(0.9); /* Back to original size */
  }
}

