#page-topbar{
  left: 0 !important;
}

.header-logo{
  margin-left: 9px !important;
}

.vertical-menu{
  top: 70px !important;
  padding-top: 0 !important;
}

