.image-cards {
  cursor: pointer;
  border-radius: 10px;
  flex: 1;
  text-transform: uppercase;
  transition: transform 0.3s ease-in-out;
  height: 230px !important;

  &:hover {
    animation: bounce 1s ease-in-out infinite;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
    .custom-card-body {
      text-align: center;
      background-color: #005000 !important;
      color: #ffffff;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  }

  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }

}

.card-container {
  display: flex;
  flex-direction: row;
}

.card-img-top{
  animation: fadeIn 2s ease-in-out 1;
}

 .custom-card-img {
  width: 70px !important;
  height: 70px !important;
  left: 70px !important;
  margin-left: 40% !important;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.custom-card-body {
  text-align: center;
  background-color: #D5E3D5 !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card-wrapper {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  animation: slideInFromLeft 1s ease-out;
  padding-top: 20px !important;
  padding-bottom: 70px !important;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}


.animate-element{
  display: inline-block;
  animation: bounce 0.5s ease-in-out infinite;
  border: 1px solid #000;
  transition: transform 0.3s ease, border-top-right-radius 0.3s ease;
}